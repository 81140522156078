import React, { Component } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListEventComponent from "../Events/ListEventComponent";
import { GetSeasonList } from "../../actions-index";
import _ from "lodash";

class SeasonSelect extends Component {
	constructor(props) {
		super(props);
		this.handleCatChange = this.handleCatChange.bind(this);
		this.handleYearChange = this.handleYearChange.bind(this);
		this.state = {
			isLoading: true,
			is2Loading: true,
			category: "",
			selectedYear: "",
			eventSeason: "",
			dataSource: {},
			eventsBySeason: [],
			uniqueCats: [],
			seasonYears: [],
			allSeasons: [],
			refreshEventList: true,
		};
	}
	async componentDidMount() {
		const seasonData = await GetSeasonList();
		this.setState({
			uniqueCats: _.sortBy(seasonData.uniqueCategories),
			seasonYears: _.sortBy(seasonData.uniqueYears, ["SeasonYear"]),
		});
		if (this.props.sid.length > 0) {
			const selectedseason = this.state.seasonYears.find(
				(item) => item.pk === this.props.sid
			);
			this.setState({
				category: selectedseason.data,
				selectedYear: selectedseason.SeasonYear,
				eventSeason: selectedseason.pk,
			});
		} else if (localStorage.getItem("selectedseason") && localStorage.getItem("selectedseason") !== "") {
			const cachedseason = this.state.seasonYears.find(
				(item) => item.pk === localStorage.getItem("selectedseason")
			);
			this.setState({
				category: cachedseason.data || "",
				selectedYear: cachedseason.SeasonYear || "",
				eventSeason: cachedseason.pk || "",
			});
		}
	}

	handleCatChange = (event) => {
		const selectedCat = event.target.value;
		this.setState({ category: selectedCat });
		this.setState({
			eventSeason: `${selectedCat}-${this.state.selectedYear}`,
		});
	};
	handleYearChange = (event) => {
		const selectedYr = event.target.value;
		this.setState({ selectedYear: selectedYr });
		this.setState({
			eventSeason: `${this.state.category}-${selectedYr}`,
		});
		localStorage.setItem(
			"selectedseason",
			`${this.state.category}-${selectedYr}`
		);
	};
	render() {
		const {
			category,
			eventSeason,
			selectedYear,
			uniqueCats,
			seasonYears,
		} = this.state;

		const formControl = {
			margin: 1,
			minWidth: 200,
			fontSize: 16,
		};
		return (
			<div>
				<FormControl style={formControl}>
					<InputLabel id="season-select-label">
						<strong>
							<big>Select Season</big>
						</strong>
					</InputLabel>
					<Select
						labelid="season-select-label"
						id="season-select"
						value={category}
						onChange={this.handleCatChange}
					>
						{/* <MenuItem value="" /> */}
						{uniqueCats.map((rows) => (
							<MenuItem key={rows} value={rows}>
								{rows}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<br />
				<FormControl style={formControl}>
					<InputLabel id="year-select-label">
						<strong>
							<big>Select Year</big>
						</strong>
					</InputLabel>
					<br />
					<Select
						labelid="year-select-label"
						id="year-select"
						value={selectedYear}
						onChange={this.handleYearChange}
					>
						{/* <option value="" /> */}
						{seasonYears
							.filter(
								category
									? (value) => value.data === category
									: (value) => value.data === ""
							)
							.map((rows) => (
								<MenuItem key={rows.SeasonYear} value={rows.SeasonYear}>
									{rows.SeasonYear}
								</MenuItem>
							))}
					</Select>
				</FormControl>
				{selectedYear.length > 0 ? (
					<ListEventComponent data={eventSeason} />
				) : (
					<div></div>
				)}

				<br />
			</div>
		);
	}
}

export default SeasonSelect;
