import API from "@aws-amplify/api";
import sortBy from "lodash/sortBy";
import GetContactList from "./Shared Scripts/RefreshContactData";
import _ from "lodash";
// export const addItem = payload => {
// 	return {
// 	  type: "ADD_ITEM",
// 	  payload
// 	};
//   };
export async function GetVenues() {
  var venuedataload = await GetEventList();
  venuedataload = venuedataload.filter((event) => {
    return event.Venue !== "NULL";
  });
  const uniqueVenue = await GetUnique(venuedataload, "Venue");
  return uniqueVenue;
}
export async function getAllData(params) {
  async function _getAllData(params, startKey) {
    if (startKey) {
      params.payload.ExclusiveStartKey = startKey;
    }
    return await API.post(
      process.env.REACT_APP_TICKET_TRACKING_API_NAME,
      "/items",
      { body: params }
    );
  }
  let lastEvaluatedKey = null;
  let rows = [];
  do {
    const result = await _getAllData(params, lastEvaluatedKey);
    rows = rows.concat(result.Items);
    lastEvaluatedKey = result.LastEvaluatedKey;
  } while (lastEvaluatedKey);
  return rows;
}
export async function GetSeasonList() {
  if (
    localStorage.getItem("seasonlist") === null ||
    localStorage.getItem("uniqueCategories") === null ||
    localStorage.getItem("uniqueSeasons") === null
  ) {
    var paramsSeasons = {
      operation: "read",
      payload: {
        IndexName: "gsi_1",
        KeyConditionExpression: "sk = :objecttype",
        ExpressionAttributeValues: {
          ":objecttype": "Season",
        },
        ExpressionAttributeNames: { "#data": "data" },
        ProjectionExpression: "SeasonYear, #data, pk,Category",
      },
    };
    const seasondataload = await getAllData(paramsSeasons);
    const uniqueCategories = Array.from(
      new Set(seasondataload.map((row) => row.data))
    );
    const uniqueSeasons = await GetUnique(seasondataload, "pk");
    const uniqueYears = seasondataload;
    localStorage.setItem("seasonlist", JSON.stringify(uniqueYears));
    localStorage.setItem("uniqueCategories", JSON.stringify(uniqueCategories));
    localStorage.setItem("uniqueSeasons", JSON.stringify(uniqueSeasons));
  }
  var uniqueCategories = JSON.parse(localStorage.getItem("uniqueCategories"));
  var uniqueYears = JSON.parse(localStorage.getItem("seasonlist"));
  var uniqueSeasons = JSON.parse(localStorage.getItem("uniqueSeasons"));
  return { uniqueCategories, uniqueYears, uniqueSeasons };
}
export async function GetEventList() {
  if (localStorage.getItem("eventlist") === null) {
    var paramsEvents = {
      operation: "read",
      payload: {
        IndexName: "gsi_1",
        KeyConditionExpression: "sk = :objecttype",
        ExpressionAttributeValues: {
          ":objecttype": "Event",
        },
      },
    };
    const eventdataload = await getAllData(paramsEvents);
    const sortedEventData = _.sortBy(eventdataload, function (dateObj) {
      return new Date(dateObj.EventDate);
    });
    localStorage.setItem("eventlist", JSON.stringify(sortedEventData));
  }
  var eventlist = JSON.parse(localStorage.getItem("eventlist"));
  return eventlist;
}

export async function GetTicketCategories() {
  if (localStorage.getItem("ticketCategories") === null) {
    let paramsTicketCatID = {
      operation: "read",
      payload: {
        IndexName: "gsi_1",
        KeyConditionExpression: "sk = :objecttype",
        ExpressionAttributeValues: {
          ":objecttype": "TicketCategory",
        },
        ProjectionExpression: "description",
      },
    };

    let ticketcatdataload = await getAllData(paramsTicketCatID);
    let uniqueTicketCategories = await GetUnique(
      ticketcatdataload,
      "description"
    );
    localStorage.setItem(
      "ticketCategories",
      JSON.stringify(uniqueTicketCategories)
    );
  }
  let uniqueTicketCategories = JSON.parse(
    localStorage.getItem("ticketCategories")
  );
  return uniqueTicketCategories;
}
export async function GetRequestStatuses() {
  if (localStorage.getItem("requestStatuses") === null) {
    var paramsStatus = {
      operation: "read",
      payload: {
        IndexName: "gsi_1",
        KeyConditionExpression: "sk = :objecttype",
        ExpressionAttributeNames: {
          "#data": "data",
        },
        ExpressionAttributeValues: {
          ":objecttype": "RequestStatus",
        },
        ProjectionExpression: "#data,pk",
      },
    };

    const statusdataload = await getAllData(paramsStatus);
    const uniquestatus = Array.from(
      new Set(statusdataload.map((row) => row.data))
    );
    const uniqueRequestStatuses = uniquestatus.map(function (tcid) {
      return { value: tcid, text: tcid, key: tcid };
    });
    localStorage.setItem(
      "requestStatuses",
      JSON.stringify(uniqueRequestStatuses)
    );
  }
  let uniqueRequestStatuses = JSON.parse(
    localStorage.getItem("requestStatuses")
  );
  return uniqueRequestStatuses;
}
export async function GetUnique(data, fieldName) {
  const unique = sortBy(
    Array.from(new Set(data.map((row) => row[fieldName])))
  ).map(function (org) {
    return {
      value: org,
      text: org,
      key: org,
    };
  });
  return unique;
}
export async function BuildContactInfo() {
  var paramsContact = {
    operation: "read",
    payload: {
      IndexName: "gsi_1",
      KeyConditionExpression: "sk = :objecttype",
      ExpressionAttributeValues: {
        ":objecttype": "Contact",
      },
      ExpressionAttributeNames: { "#data": "data" },
      ProjectionExpression:
        "#data,pk,EmailAddress,EmailAddress2,FirstName,LastName,Organization,Honorific,Salutation,NameSuffix,P1Description,P2Description,P3Description,ContactNotes",
    },
  };

  const responseData = await getAllData(paramsContact);
  var FieldNames = ["Organization", "Honorific", "Salutation", "NameSuffix"];
  var Data = [];
  FieldNames.forEach(async function (element) {
    const UniqueItems = await GetUnique(responseData, element);
    Data[`${element}`] = UniqueItems;
  });
  return Data;
}
export async function GetContactData() {
  if (JSON.parse(localStorage.getItem("contactdata")) !== null) {
    const responsearray = JSON.parse(localStorage.getItem("contactdata"));
    return responsearray;
  } else {
    const responseData = await GetContactList();
    return responseData;
  }
}
export async function GetContactInfo(contactid) {
  var paramsContact = {
    operation: "get",
    payload: {
      Key: {
        pk: contactid,
        sk: "Contact",
      },
      ExpressionAttributeNames: { "#data": "data" },
      ProjectionExpression:
        "pk,FirstName,LastName,Organization,#data, EmailAddress,EmailAddress2,IsOrganization,Honorific,Title,NameSuffix,Salutation,ContactNotes,ParkingNotes",
    },
  };

  const response = await API.post(
    process.env.REACT_APP_TICKET_TRACKING_API_NAME,
    "/items",
    {
      body: paramsContact,
    }
  );
  return response;
}
export async function GetStateDropdown() {
  const stateArray = [
    "AL",
    "AK",
    "AS",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "DC",
    "FM",
    "FL",
    "GA",
    "GU",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MH",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "MP",
    "OH",
    "OK",
    "OR",
    "PW",
    "PA",
    "PR",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VI",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];
  const stateDropdown = stateArray.map(function (tcid) {
    return {
      value: tcid,
      text: tcid,
      key: tcid,
    };
  });
  return stateDropdown;
}
export async function GetEventBySeason(data) {
  var eventList = await GetEventList();
  var eventData = eventList.filter((event) => {
    return event.data === data;
  });
  const sortedSeasonData = sortBy(eventData, function (dateObj) {
    return new Date(dateObj.EventDate);
  });
  return sortedSeasonData;
}
export async function GetRequestData(eventid) {
  let eventList = await GetEventList();
  let requestData = eventList.filter((event) => {
    return event.pk === eventid;
  })[0];
  return requestData;
}
export async function GetRequestDataByContact(contactid) {
  var paramsRequest = {
    operation: "read",
    payload: {
      IndexName: "gsi_1",
      KeyConditionExpression: "sk = :objecttype",
      FilterExpression: "ContactID = :contactid OR RequestedFor = :contactid",
      ExpressionAttributeValues: {
        ":objecttype": "Request",
        ":contactid": contactid,
      },
    },
  };
  const requestData = await getAllData(paramsRequest);
  return requestData;
}
export async function GetPaymentMethods() {
  if (localStorage.getItem("paymentmethods")) {
    const responsearray = JSON.parse(localStorage.getItem("paymentmethods"));
    return responsearray;
  } else {
    var paramsPM = {
      operation: "read",
      payload: {
        IndexName: "gsi_1",
        KeyConditionExpression: "sk = :objecttype",
        ExpressionAttributeValues: {
          ":objecttype": "PaymentMethod",
        },
      },
    };
    const requestData = await getAllData(paramsPM);
    localStorage.setItem("paymentmethods", JSON.stringify(requestData));
    return requestData;
  }
}
