import React, { Component } from "react";
import API from "@aws-amplify/api";
import "react-datepicker/dist/react-datepicker.css";
import { GetCurrentDateTime, generateRowId } from "../../util/util";
import { getAllData } from "../../actions-index";
// import Notifier, { openSnackbar } from "../../../Common/components/Notifier";

export default class LoadFakeTickets extends Component {
  constructor(props) {
    super(props);
    props.generateTitle();
    this.eventid = props.eventid;
    this.getTickets = this.getTickets.bind(this);
    // this.showNotifier = this.showNotifier.bind(this);
    this.updateTicket = this.updateTicket.bind(this);
    this.state = {
      data: null,
      eventseatdata: null,
      openapprove: false,
      containerRef: null,
      stageRef: null,
      selectedSeats: [],
      selectedSeatsIds: null,
      selectedNewSeatsIds: [],
      seatId: [],
      currentContact: {},
      ticketsByEvent: [],
      isLoading: true,
      message: "",
      venueCategories: [],
    };
    this.user = sessionStorage.getItem("UserName");
  }
  async componentDidMount() {
    this.getTickets();
  }

  async GetTicketFields(ticket) {
    ticket.Seat =
      "Section:" +
      ticket.Section +
      " Row:" +
      ticket.Row +
      " Seat:" +
      ticket.Seat;
    ticket.isAssigned = false;
    if (ticket.ContactID === this.state.currentContact.pk) {
      this.state.selectedSeats.push(ticket.pk);
      ticket.isAssigned = true;
    }
    return ticket;
  }

  async getTickets() {
    var paramsTickets = {
      operation: "read",
      payload: {
        IndexName: "gsi_1",
        KeyConditionExpression: "sk = :objecttype",
        FilterExpression: "EventID = :eventid ",
        ExpressionAttributeValues: {
          ":objecttype": "Ticket",
          ":eventid": "event-439",
        },
        ExpressionAttributeNames: {
          "#Row": "Row",
          "#data": "data",
          "#Section": "Section",
        },
        ProjectionExpression:
          "pk,sk,#data,EventID,TicketType,TicketCategoryID,PayableTo,PayableToID,ContactInfo,ContactID,Price,RequestID,#Row,Seat,#Section,TicketSource,TicketSourceID,TicketStatus",
      },
    };
    await getAllData(paramsTickets).then((response) => {
      this.setState({ ticketsByEvent: response });
    });
    this.state.ticketsByEvent.forEach(async function (ticket) {
      await this.updateTicket(ticket);
    }, this);
  }

  async updateTicket(ticket) {
    const datetime = await GetCurrentDateTime();
    var paramsAdd = {
      operation: "create",
      payload: {
        Item: {
          pk: "ticket-" + generateRowId(4),
          sk: "Ticket",
          data: this.eventid,
          EventID: this.eventid,
          PayableTo: "NULL",
          PayableToID: ticket.PayableToID,
          Price: ticket.Price,
          RequestID: "NONE",
          Row: ticket.Row,
          Seat: ticket.Seat,
          Section: ticket.Section,
          TicketSource: ticket.TicketSource,
          TicketSourceID: ticket.TicketSourceID,
          TicketStatus: "Available",
          TicketType: ticket.TicketType,
          ContactID: "NONE",
          Venue: "Other",
          LastModifiedBy: this.user,
          LastModifiedDate: datetime,
        },
      },
    };
    await API.post(process.env.REACT_APP_TICKET_TRACKING_API_NAME, "/items", {
      body: paramsAdd,
    });
  }
  render() {
    if (this.state.isLoading) {
      return <div>Loading Request Data...</div>;
    } else {
      return <div> </div>;
    }
  }
}
