import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import { TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import { Route, Link } from "react-router-dom";
// import EditEventComponent from "./EditEventComponent";
import _ from "lodash";
import { GetEventList, GetSeasonList } from "../../util/dropdownconstants";

class App extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    // const matchMedia = window.matchMedia("(prefers-color-scheme: dark)")
    // 	.matches;

    this.state = {
      // darkMode: matchMedia,
      isLoading: true,
      is2Loading: true,
      page: "",
      dataSource: {},
      eventsBySeason: [],
      seasoncat: "",
      seasonyear: "",
      selectedSeason: "",
      allSeasons: [],
      column: null,
      direction: null,
    };
    this.setDataReceivedFromChild = this.setDataReceivedFromChild.bind(this);
    this.RedirectToTickets = this.RedirectToTickets.bind(this);
  }
  RedirectToTickets = (eventid) => {
    console.log(eventid);
    console.log(this.props);
    this.props.history.push({
      pathname: "/tickets",
      state: { event: eventid },
    });
    // const history = useHistory();
    // history.push({
    //   pathname: `/tickets`,
    //   eventid: eventid,
    //   search: "search",
    //   state: {
    //     type: "type",
    //   },
    // });
  };
  handleCancel = () => this.setState({ openTickets: false });
  setDataReceivedFromChild = (index) => {
    if (index === "Cancel") {
      this.setState({ openTickets: false });
    } else {
      this.setState({ openTickets: false });
      this.showNotifier(index);
    }
  };
  handleSort = (clickedColumn) => () => {
    const { column, eventsBySeason, direction } = this.state;

    if (column !== clickedColumn) {
      if (clickedColumn === "EventDate") {
        this.setState({
          column: clickedColumn,
          eventsBySeason: _.sortBy(eventsBySeason, function (dateObj) {
            return new Date(dateObj.EventDate);
          }),
          direction: "ascending",
        });
      } else {
        this.setState({
          column: clickedColumn,
          eventsBySeason: _.sortBy(eventsBySeason, [
            (eventsBySeason) =>
              _.get(eventsBySeason, clickedColumn).toLowerCase(),
          ]),
          direction: "ascending",
        });
      }
      return;
    }

    this.setState({
      eventsBySeason: eventsBySeason.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };
  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.GetData(this.props.data);
    }
  }
  async componentDidMount() {
    this.GetData(this.props.data);
  }
  async GetData(data) {
    try {
      this.setState({ selectedSeason: data });
      const eventList = await GetEventList();
      console.log(eventList);
      const eventData = eventList.filter((item) => {
        return item.data === data;
      });
      const sortedSeasonData = _.sortBy(eventData, function (dateObj) {
        return new Date(dateObj.EventDate);
      });

      this.setState({ eventsBySeason: sortedSeasonData, isLoading: false });
    } catch (err) {
      console.log(err);
    }
    try {
      const seasonData = await GetSeasonList();
      console.log(seasonData);
      this.setState({ allSeasons: seasonData.uniqueYears, is2Loading: false });
    } catch (err) {}
  }

  render() {
    const { column, eventsBySeason, direction, isLoading, is2Loading } =
      this.state;
    const EditEventComponent = React.lazy(() => import("./EditEventComponent"));
    if (isLoading || is2Loading) {
      return <div>Loading Events...</div>;
    } else if (this.state.page === "editevent") {
      return (
        <div>
          <EditEventComponent></EditEventComponent>
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <Typography variant="h4" style={style}>
            Season Events
          </Typography>
          <Link to={`/addevent/${this.state.selectedSeason}`}>
            <button style={scarletWhite}>Add Event</button>
          </Link>
          <Table sortable celled fixed className={"SuiTable"}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  style={scarletWhite}
                  sorted={column === "EventName" ? direction : null}
                  onClick={this.handleSort("EventName")}
                >
                  Event Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={scarletWhite}
                  sorted={column === "EventDate" ? direction : null}
                  onClick={this.handleSort("EventDate")}
                >
                  Event Date
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={scarletWhite}
                  sorted={column === "Venue" ? direction : null}
                  onClick={this.handleSort("Venue")}
                >
                  Venue
                </Table.HeaderCell>
                <Table.HeaderCell style={scarletWhite}></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <TableBody>
              {_.map(eventsBySeason, ({ pk, EventName, EventDate, Venue }) => (
                <TableRow style={tablestyle} key={pk}>
                  <TableCell component="th" scope="row">
                    {EventName}
                  </TableCell>
                  <TableCell>{EventDate}</TableCell>
                  <TableCell>{Venue}</TableCell>
                  <TableCell>
                    <Link to={`/event/${pk}`}>Edit Event</Link>
                    <br />
                    <Link to={`/requests/${pk}`}>View Requests</Link>
                    <br />
                    <Link to={`/addrequest/${pk}`}>Add Request</Link>
                    <br />
                    <Link
                      to={{
                        pathname: "/tickets",
                        eventid: pk,
                        seasonid: this.state.selectedSeason,
                      }}
                    >
                      Manage Tickets
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Route exact path="/event/:id" component={EditEventComponent} />
        </div>
      );
    }
  }
}
const style = {
  display: "flex",
  justifyContent: "center",
};
const tablestyle = {
  cursor: "pointer",
};
const scarletWhite = {
  backgroundColor: "#BB0000",
  color: "#fff",
};

export default App;
