import Auth from "@aws-amplify/auth";

const apiConfig = {
  aws_user_pools: "enable",
  aws_user_pools_mfa_type: "OFF",
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,

  customAuthStorage: {
    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //'domain': process.env.REACT_APP_CUSTOM_AUTH_STORAGE_DOMAIN,
    // OPTIONAL - Cookie path
    path: "/",
    // OPTIONAL - Cookie expiration in days
    // 'expires': 0,
    // OPTIONAL - Cookie secure flag
    secure:
      process.env.REACT_APP_CUSTOM_AUTH_STORAGE_SECURE === "true"
        ? true
        : false,
  },

  oauth: {
    // 'identityPoolId' : process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    idp: process.env.REACT_APP_SAML_PROVIDER,
    // Domain name
    domain: process.env.REACT_APP_OAUTH_DOMAIN,

    // Authorized scopes
    scope: ["aws.cognito.signin.user.admin", "openid", "profile"],

    // Callback URL
    redirectSignIn: process.env.REACT_APP_SIGNIN,
    redirectSignOut: process.env.REACT_APP_SIGNOUT,

    // 'code' for Authorization code grant,
    // 'token' for Implicit grant
    responseType: "code",
  },
  API: {
    endpoints: [
      {
        name: process.env.REACT_APP_TICKET_TRACKING_API_NAME,
        endpoint: process.env.REACT_APP_TICKET_TRACKING_API_URL,
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession()).idToken.jwtToken,
          };
        },
      },
    ],
  },
  Analytics: {
    disabled: true,
  },
};

export default apiConfig;
