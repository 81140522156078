import React, { useRef, useEffect } from "react";
import { AppBar as MuiAppBar } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import { Link as RouterLink } from "react-router-dom";
import useStyles from "./styles/theme";
import SkipTo from '../Common/components/SkipTo';
import { PROJECT_NAME } from "../util/constants";
//import LoginButton from './LoginButton';
import PropTypes from "prop-types";
import BuckeyeLeaf from "../Common/BuckeyeLeaf-Active.svg";

function AppHeader(props) {
	const { determineHeight, className, elevation } = props;
	const ref = useRef({ current: { clientHeight: null } });
	useEffect(() => {
		determineHeight(ref.current.clientHeight);
	}, [ref, determineHeight]);

	const classes = useStyles();

	return (
		<MuiAppBar
			ref={ref}
			elevation={elevation}
			className={className}
			position="sticky"
			color="inherit"
		>
			<SkipTo />
			<Toolbar style={{ borderBottom: "solid 3px #bb0000" }}>
				<ButtonBase
					className="d-flex align-items-center mr-auto"
					aria-label="Navigate to home"
					component={RouterLink}
					to="/"
				>
					<img
						className={`${classes.icon} ${classes.brandIcon}`}
						alt="Buckeye Leaf"
						src={BuckeyeLeaf}
					/>
					<Typography
						color="secondary"
						classes={{ root: classes.title }}
						className="py-2"
						component="button"
						noWrap
					>
						{PROJECT_NAME}
					</Typography>
					{/* <LoginButton /> */}
				</ButtonBase>
				{props.children}
			</Toolbar>
		</MuiAppBar>
	);
}

export default AppHeader;

AppHeader.defaultProps = {
	className: "",
	elevation: 0,
	determineHeight: (height) => ({ height }),
};

AppHeader.propTypes = {
	elevation: PropTypes.number,
	className: PropTypes.string,
	darkMode: PropTypes.bool,
	determineHeight: PropTypes.func,
};
