import API from "@aws-amplify/api";
import sortBy from "lodash/sortBy";
import { getAllData } from "../../actions-index";

export async function GetPaymentsByContact(contactid) {
  var allData = [],
    paymentsdata = [];
  var paramsRequest = {
    operation: "read",
    payload: {
      IndexName: "gsi_1",
      FilterExpression: "ContactID = :id",
      KeyConditionExpression: "sk=:objecttype",
      ExpressionAttributeValues: {
        ":objecttype": "LineItem",
        ":id": contactid,
      },
      ProjectionExpression: "PaymentID",
    },
  };
  const contactresults = await getAllData(paramsRequest);
  var paramsRequestedFor = {
    operation: "read",
    payload: {
      IndexName: "gsi_1",
      KeyConditionExpression: "sk=:objecttype",
      FilterExpression: "RequestedFor = :id",
      ExpressionAttributeValues: {
        ":objecttype": "LineItem",
        ":id": contactid,
      },
      ProjectionExpression: "PaymentID",
    },
  };
  const requestedforresults = await getAllData(paramsRequestedFor);
  var paramsPaidBy = {
    operation: "read",
    payload: {
      IndexName: "gsi_1",
      KeyConditionExpression: "sk=:objecttype",
      FilterExpression: "PayerID = :id",
      ExpressionAttributeValues: {
        ":objecttype": "Payment",
        ":id": contactid,
      },
      ProjectionExpression: "pk",
    },
  };
  const paidbyresults = await getAllData(paramsPaidBy);
  allData = [...contactresults, ...requestedforresults, ...paidbyresults];
  const paymentids = Array.from(
    new Set(allData.map((row) => (row.PaymentID ? row.PaymentID : row.pk)))
  );
  for await (let payment of paymentids) {
    var paymentdata = await GetPaymentByPaymentID(payment);
    paymentsdata = paymentsdata.concat(paymentdata);
  }
  return paymentsdata;
}

export async function GetPaymentsBySeason(event, season) {
  var paymentsdata = [],
    reqarray = [],
    paramsLineItems = {},
    requestData = [];
  if (event) {
    paramsLineItems = {
      operation: "read",
      payload: {
        IndexName: "gsi_1",
        KeyConditionExpression: "sk = :objecttype",
        FilterExpression: "EventID = :eventid",
        ExpressionAttributeValues: {
          ":objecttype": "LineItem",
          ":eventid": event,
        },
        ProjectionExpression: "PaymentID",
      },
    };
  } else {
    paramsLineItems = {
      operation: "read",
      payload: {
        IndexName: "gsi_1",
        KeyConditionExpression: "sk = :objecttype",
        FilterExpression: "EventSeason = :eventseason",
        ExpressionAttributeValues: {
          ":objecttype": "LineItem",
          ":eventseason": season,
        },
        ProjectionExpression: "PaymentID",
      },
    };
  }
  requestData = await getAllData(paramsLineItems);
  if (requestData.length > 0) {
    reqarray = Array.from(
      new Set(requestData.map((lineitem) => lineitem.PaymentID))
    );
    paymentsdata = await GetPaymentsByPaymentIDs(reqarray);
  }
  return paymentsdata;
}
export async function GetPaymentsByPaymentIDs(paymentids) {
  var titleObject = [],
    basictitleObject = {};
  var index = 0,
    currentIndex = 0,
    counter = 0,
    max = 99,
    titleKey = "",
    filterExp = "";
  titleObject[currentIndex] = {};
  paymentids.forEach(function (id) {
    index++;
    titleKey = ":titlevalue" + index;
    basictitleObject[titleKey.toString()] = id;
    if (counter < max) {
      titleObject[currentIndex][titleKey] = id;
      counter++;
    } else {
      currentIndex++;
      titleObject[currentIndex] = {};
      titleObject[currentIndex][titleKey] = id;
      counter = 0;
    }
  });
  for (let i = 0; i < titleObject.length; i++) {
    if (i === 0) {
      filterExp += "pk IN (" + Object.keys(titleObject[i]).toString() + ")";
    } else {
      filterExp +=
        " OR pk IN (" + Object.keys(titleObject[i]).toString() + ") ";
    }
  }
  var paramsPayments = {
    operation: "read",
    payload: {
      IndexName: "gsi_1",
      KeyConditions: {
        sk: {
          ComparisonOperator: "EQ",
          AttributeValueList: { S: "Payment" },
        },
      },
      FilterExpression: filterExp,
      ExpressionAttributeValues: basictitleObject,
    },
  };
  const paymentData = await getAllData(paramsPayments);
  paymentData.AmountApplied = paymentData.AmountApplied
    ? paymentData.AmountApplied
    : 0;
  return paymentData;
}
export async function GetPaymentByPaymentID(paymentid) {
  var paramsPayments = {
    operation: "get",
    payload: {
      Key: {
        pk: paymentid,
        sk: "Payment",
      },
    },
  };
  const paymentarray = await API.post(
    process.env.REACT_APP_TICKET_TRACKING_API_NAME,
    "/items",
    {
      body: paramsPayments,
    }
  );
  paymentarray.Item.AmountApplied = 0;
  return paymentarray.Item;
}
export async function GetLineItemsByPayments(paymentsinfo) {
  var requestarray = [];
  paymentsinfo.forEach(async (element) => {
    var paramsPayments = {
      operation: "read",
      payload: {
        IndexName: "gsi_1",
        KeyConditionExpression: "sk = :objecttype",
        FilterExpression: "PaymentID = :paymentid",
        ExpressionAttributeValues: {
          ":objecttype": "LineItem",
          ":paymentid": element.pk,
        },
      },
    };
    const array = await getAllData(paramsPayments);
    requestarray.push(array);
  });
  return requestarray;
}
export async function GetLineItemsByPayment(paymentsinfo) {
  var paramsPayments = {
    operation: "read",
    payload: {
      IndexName: "gsi_1",
      KeyConditionExpression: "sk = :objecttype",
      FilterExpression: "PaymentID = :paymentid",
      ExpressionAttributeValues: {
        ":objecttype": "LineItem",
        ":paymentid": paymentsinfo,
      },
    },
  };
  const requestarray = await getAllData(paramsPayments);
  await requestarray.forEach(async (lineitem) => {
    var paramsLineItems = {
      operation: "get",
      payload: {
        Key: {
          pk: lineitem.TicketID,
          sk: "Ticket",
        },
      },
    };
    const ticketarray = await API.post(
      process.env.REACT_APP_TICKET_TRACKING_API_NAME,
      "/items",
      {
        body: paramsLineItems,
      }
    );
    lineitem.TicketPrice = ticketarray.Item.Price
      ? ticketarray.Item.Price.toString()
      : 0;
  });
  return requestarray;
}
export async function GetUnpaidTickets() {
  var paramsTickets = {
    operation: "read",
    payload: {
      IndexName: "gsi_1",
      KeyConditionExpression: "sk = :objecttype",
      FilterExpression:
        "TicketStatus=:ts and AmountPaid < Price and TicketType<>:tt1 and TicketType<>:tt2 and RequestChargeType<>:tct",
      ExpressionAttributeValues: {
        ":objecttype": "Ticket",
        ":ts": "Allocated",
        ":tt1": "Staff",
        ":tt2": "Season",
        ":tct": "No Charge",
      },
    },
  };
  var requestData = await getAllData(paramsTickets);
  const responseData = sortBy(requestData, function (dateObj) {
    return new Date(dateObj.EventDate);
  });
  return responseData;
}
