import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import API from "@aws-amplify/api";
import { Form, Message } from "semantic-ui-react";
import { GetCurrentDateTime } from "../../../util/util";
import { Chip, Typography } from "@material-ui/core";

export default function PaymentEmail({
  emaillist,
  row,
  fullName,
  description,
  season,
}) {
  var [checked, setChecked] = React.useState([emaillist[0]]);
  var [addEmail, setAddEmail] = React.useState("");
  var [emailError, setEmailError] = React.useState(false);
  var [submitted, setSubmitted] = React.useState(false);
  async function handleChange(e, { value }) {
    setAddEmail(value);
  }
  async function handleClick() {
    const re =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (
      re.test(addEmail.toLowerCase()) &&
      emaillist.indexOf(addEmail.toLowerCase()) === -1
    ) {
      handleSelect(addEmail);
      emaillist.push(addEmail);
      setEmailError(false);
      setAddEmail("");
    } else {
      setEmailError(true);
    }
  }
  async function handleSelect(email) {
    const currentIndex = checked.indexOf(email);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(email);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  }
  async function onSubmit(email) {
    const user = sessionStorage.getItem("UserName");
    const datetime = await GetCurrentDateTime();
    var emaillist = checked.join(",");
    if (row.PaymentRequests) {
      row.PaymentRequests.push({
        PaymentRequestSentBy: user,
        PaymentRequestSentTo: emaillist,
        DatePaymentRequestSent: datetime,
      });
    } else {
      row.PaymentRequests = [
        {
          PaymentRequestSentBy: user,
          PaymentRequestSentTo: emaillist,
          DatePaymentRequestSent: datetime,
        },
      ];
    }
    var totalPrice = row.totalprice;
    var eventId = row.EventID;
    var contactId = row.ContactID;
    var paramsUpdate = {
      operation: "update",
      payload: {
        Key: {
          pk: row.pk,
          sk: "Request",
        },
        UpdateExpression: "set PaymentRequests=:pr",

        ExpressionAttributeValues: {
          ":pr": row.PaymentRequests,
        },
        ReturnValues: "UPDATED_NEW",
      },
    };
    await API.post(process.env.REACT_APP_TICKET_TRACKING_API_NAME, "/items", {
      body: paramsUpdate,
    });
    var params = {
      name: fullName,
      amount: totalPrice,
      pk: row.pk,
      destination: user,
      contactId: contactId,
      userChoice1: eventId,
      contactEmail: emaillist,
      description: description,
      season: season,
    };
    API.post(process.env.REACT_APP_TICKET_TRACKING_API_NAME, "/email", {
      body: params,
    });

    setSubmitted(true);
  }

  return (
    <Paper>
      <Typography variant="h5">Confirm Contact's Email Address</Typography>
      <br />
      <Grid item xs>
        {emaillist.map((email) => {
          return (
            <Chip
              key={email}
              color={checked.indexOf(email) !== -1 ? "primary" : "secondary"}
              label={email}
              onClick={() => handleSelect(email)}
              className={"chip"}
            />
          );
        })}
      </Grid>
      <br />
      <Grid item>
        <Form.Group>
          <Form.Input
            placeholder="Email Address"
            className="form-control"
            id="Email"
            value={addEmail}
            onChange={handleChange}
          />
          <Message negative hidden={!emailError}>
            <p>
              Email Address is invalid or a duplicate of one already displayed
              in the above list of contact email addresses.
            </p>
          </Message>
          <Form.Button onClick={handleClick}>Add Additional Email</Form.Button>
        </Form.Group>
      </Grid>
      <br />
      <Grid item xs>
        Payment Amount Requested: ${row.totalprice}
      </Grid>
      <br />
      <div
        data-tooltip={
          row.totalprice > 0
            ? submitted
              ? "Email Sent"
              : null
            : "No Payment Amount for this request: Check that ticket(s) are assigned and that ticket prices are not set to $0 "
        }
        data-position="top left"
      >
        <Form.Button
          type="submit"
          className="badge badge-success"
          disabled={!row.totalprice > 0 || submitted}
          data-position="top center"
          onClick={() => onSubmit()}
        >
          Send Payment Link to{" "}
          {/* {checked.length > 1 ? checked.map((email) => email + ", ") : checked[0]} */}
          {checked.join()}
        </Form.Button>
      </div>
    </Paper>
  );
}
