import React, { Fragment, useEffect } from "react";
import "../styles/index.css";
import PendingRequests from "../dashboard/ListPendingRequests";
import Sidebar from "../../Sidebar/containers";
import { Typography } from "@material-ui/core";

function Home(props) {
	const { generateTitle } = props;
	useEffect(() => {
		generateTitle("Ticket Tracking System");
	}, [generateTitle]);
	return (
		<Fragment>
			<h1 className="h-accessible-text">Ticket Tracking System</h1>
			<Typography variant="body2" component={Sidebar} />
			<PendingRequests seasons={props.seasonlistchecked}/>
		</Fragment>
	);
}

export default Home;
