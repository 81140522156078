import React, { Fragment, useEffect } from "react";
// import "../styles/index.css";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Collapse,
  Hidden,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { orderBy } from "lodash";
import Toolbar from "@material-ui/core/Toolbar";
import useStyles from "../../styles/theme";
import { GetSeasonList } from "../../../util/dropdownconstants";
function Sidebar(props) {
  const { addItem } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [seasonFullArray, setSeasonFullArray] = React.useState();
  const [seasonCategoryList, setSeasonCategoryList] = React.useState();
  const [checked, setChecked] = React.useState(
    JSON.parse(localStorage.getItem("seasonlistchecked")) || ""
  );
  if (
    !JSON.parse(localStorage.getItem("seasonlistchecked")) &&
    JSON.parse(localStorage.getItem("seasonlist"))
  ) {
    var today = new Date();
    var currentYearObjs = JSON.parse(localStorage.getItem("seasonlist")).filter(
      (item) => item.SeasonYear.startsWith(today.getFullYear())
    );
    var checkedlist =
      JSON.parse(localStorage.getItem("seasonlistchecked")) || "";
    var newChecked = [...checkedlist];
    for (let season of currentYearObjs) {
      newChecked.push(season.pk);
    }
    localStorage.setItem("seasonlistchecked", JSON.stringify(newChecked));
    // addItem();
    setChecked(newChecked);
  }
  useEffect(() => {
    addItem();
  }, [checked, addItem]);
  const handleClick = (text) => () => {
    setOpen((prevState) => {
      return { ...prevState, [text]: !prevState[text] };
    });
  };
  useEffect(() => {
    async function datalist() {
      await GetSeasonList();
      setSeasonFullArray(JSON.parse(localStorage.getItem("seasonlist")));
      setSeasonCategoryList(
        Array.from(
          new Set(
            JSON.parse(localStorage.getItem("seasonlist")).map(
              (row) => row.data
            )
          )
        )
      );
    }
    datalist();
  }, [props]);
  function HandleToggle(value) {
    var checkedlist =
      JSON.parse(localStorage.getItem("seasonlistchecked")) || "";
    const currentIndex = checkedlist.indexOf(value);
    const newChecked = [...checkedlist];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    localStorage.setItem("seasonlistchecked", JSON.stringify(newChecked));
    // addItem();
    setChecked(newChecked);
  }

  const drawer = (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      anchor="top"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List component="nav" aria-labelledby="nested-list">
          {seasonCategoryList
            ? seasonCategoryList.map((cat) => (
                <Fragment key={`section-${cat}`}>
                  <ListItem button key={`${cat}`} onClick={handleClick(cat)}>
                    <ListItemIcon edge="end" aria-label="delete">
                      {open[cat] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemIcon>
                    <ListItemText primary={cat} />
                  </ListItem>
                  <Collapse in={open[cat]} timeout="auto" unmountOnExit>
                    {orderBy(
                      seasonFullArray.filter((element) => {
                        return element.data === cat;
                      }),
                      ["SeasonYear"],
                      ["desc"]
                    ).map(({ pk, SeasonYear }) => (
                      <ListItem
                        button
                        key={`${pk}`}
                        onClick={() => {
                          HandleToggle(pk);
                        }}
                      >
                        <ListItemIcon edge="end" aria-label="delete">
                          <Checkbox
                            edge="start"
                            tabIndex={-1}
                            disableRipple
                            checked={checked.indexOf(pk) !== -1}
                            inputProps={{ "aria-labelledby": pk }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={SeasonYear} />
                      </ListItem>
                    ))}
                  </Collapse>
                </Fragment>
              ))
            : null}
        </List>
      </div>
    </Drawer>
  );
  return (
    <Fragment>
      <Hidden smDown>{drawer}</Hidden>
    </Fragment>
  );
}
export default Sidebar;
