import React, { useContext } from "react";
import { Paper, Container, Typography, Link, Box } from "@material-ui/core";
import { AppContext } from "../App/context";
import useStyles from "./styles/theme";
import "../styles/index.scss";

import mark from "../Common/osu-web-header-horiz.png";
function Footer(props = {}) {
	const context = useContext(AppContext) || {};
	const { darkMode, elevation = {} } = context;
	const classes = useStyles();
	const { className } = props;
	const ExternalLink = (props = {}) => (
		<Link target="_blank" rel="noopener" {...props}></Link>
	);
	return (
		<Paper
			elevation={elevation.high}
			component="footer"
			className={`${className} ${classes.footer} footer${
				darkMode ? "-dark" : ""
			}`.trim()}
			id="footer"
		>
			<Container>
				<Box classes={{ root: classes.graphicsWrapper }}>
					<ExternalLink
						title="The Ohio State University home page"
						href="https://www.osu.edu/"
					>
						<img
							alt="The Ohio State University Wordmark"
							className={"osu-wordmark"}
							src={darkMode ? mark : mark}
						/>
					</ExternalLink>
				</Box>
				<Typography variant="body2">
					© 2020 - Office of the Chief Information Officer
				</Typography>
				<Typography variant="body2">
					Mount Hall | 1050 Carmack Road | Columbus, Ohio 43210
				</Typography>
				<Typography variant="body2">
					<span className="mr-1">
						Contact:{" "}
						<ExternalLink href="https://ocio.osu.edu/help">
							IT Service Desk
						</ExternalLink>
					</span>
					|
					<span className="mx-1">
						Phone: <Link href="tel:6146884357">614-688-4357</Link>
					</span>
					|
					<span className="ml-1">
						Text Telephone for the Deaf: 614-688-8743
					</span>
				</Typography>
				<Typography variant="body2" className="mt-2">
					If you have a disability and experience difficulty accessing this
					content, please call the Accessibility Helpline at{" "}
					<Link href="tel:+16142925000">614-292-5000</Link>.
				</Typography>
			</Container>
		</Paper>
	);
}
export default Footer;
