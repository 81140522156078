/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_content_delivery_bucket": "tickettrackingsystemprod-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d10w04n0mcsj5d.cloudfront.net"
};


export default awsmobile;
