import React, { Component, Fragment } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import Invoice from "./Invoice";
// import { PDFDownloadLink } from "@react-pdf/renderer";
import API from "@aws-amplify/api";
import { GetCurrentDate } from "../../util/util";
import { getAllData } from "../../actions-index";
// import Document from "./invoice-data";

class InvoiceApp extends Component {
  constructor(props) {
    super(props);
    this.getRequest = this.getRequest.bind(this);
    this.setStateFromLink = this.setStateFromLink.bind(this);
    this.state = {
      ready: true,
      foo: "Unchanged Value",
      currentRequestTickets: {
        requestid: props.requestid || "",
        RequestInfo: {},
        Items: [],
      },
      isLoading: true,
      isLoading2: true,
    };
  }
  async getRequest() {
    const requestid = this.state.currentRequestTickets.requestid;
    const currdate = await GetCurrentDate();
    var paramsRequest = {
      operation: "get",
      payload: {
        Key: {
          pk: requestid,
          sk: "Request",
        },
        // ProjectionExpression:
        //   "EventSeason,EventName,EventDate,ContactID,RequestedFor,RequestChargeType",
      },
    };
    // await API.post(process.env.REACT_APP_TICKET_TRACKING_API_NAME, "/items", {
    //   body: paramsRequest,
    // });
    await API.post(process.env.REACT_APP_TICKET_TRACKING_API_NAME, "/items", {
      body: paramsRequest,
    }).then((response) =>
      this.setState({
        currentRequestTickets: {
          ...this.state.currentRequestTickets,
          RequestInfo: response.Item,
          InvoiceDate: currdate,
        },
        isLoading2: false,
      })
    );
    if (!this.state.isLoading2) {
      var paramsContact = {
        operation: "get",
        payload: {
          Key: {
            pk: this.state.currentRequestTickets.RequestInfo.ContactID,
            sk: "Contact",
          },
        },
      };
      await API.post(process.env.REACT_APP_TICKET_TRACKING_API_NAME, "/items", {
        body: paramsContact,
      }).then((response) =>
        this.setState({
          currentRequestTickets: {
            ...this.state.currentRequestTickets,
            ContactInfo: response.Item,
          },
        })
      );
      var paramsTickets = {
        operation: "read",
        payload: {
          IndexName: "gsi_1",
          KeyConditionExpression: "sk=:objecttype",
          FilterExpression: "RequestID = :requestid",
          ExpressionAttributeValues: {
            ":requestid": requestid,
            ":objecttype": "Ticket",
          },
        },
      };
      await getAllData(paramsTickets).then((response) =>
        this.setState({
          currentRequestTickets: {
            ...this.state.currentRequestTickets,
            Items: response,
          },
          isLoading: false,
        })
      );
    }
  }
  setStateFromLink(state) {
    // set state from incoming <Link>
    if (state) {
      const { foo } = state;
      this.setState({ foo });
    }
  }
  async componentDidMount() {
    this.setStateFromLink(true);
    await this.getRequest();
    // ************************************************************************************
    // BEGIN HACKY BS - wait 1ms for props and state to settle before rendering the PDF
    // react-pdf crashes if a re-render occurs when it's already rendering.
    this.setState({ ready: false });
    setTimeout(() => {
      this.setState({ ready: true });
    }, 1);
    // END *******************************************************************************
  }
  render() {
    // const Doc = () => <Invoice invoice={this.state.currentRequestTickets} />;
    if (this.state.ready && !this.state.isLoading) {
      return (
        <Fragment>
          <PDFViewer width="1000" height="600">
            <Invoice invoice={this.state.currentRequestTickets} />
          </PDFViewer>
        </Fragment>
      );
    } else {
      return null;
    }
  }
}

export default InvoiceApp;
