import React, { useRef, useEffect, Fragment } from "react";
import { AppBar as MuiAppBar } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import { Link as RouterLink } from "react-router-dom";
import useStyles from "../../styles/theme";
import { PROJECT_NAME } from "../../../util/constants";
import SkipTo from "../../../Common/components/SkipTo";
import "../styles/index.css";
import PropTypes from "prop-types";
import buckeyeScarletWhite from "../../../Common/BuckeyeLeaf-Scarlet.svg";
import buckeyeSmoothScarletWhite from "../../../Common/BuckeyeSmooth-Scarlet.svg";

function AppBar(props) {
  const {
    darkMode = false,
    determineHeight,
    className,
    elevation,
    user,
  } = props;
  const ref = useRef({ current: { clientHeight: null } });
  useEffect(() => {
    determineHeight(ref.current.clientHeight);
  }, [ref, determineHeight]);
  const classes = useStyles();

  return (
    <MuiAppBar
      ref={ref}
      elevation={elevation}
      className={className}
      position="sticky"
      color="inherit"
    >
      <SkipTo />
      <Toolbar style={{ borderBottom: "solid 3px #bb0000" }}>
        <ButtonBase
          className="d-flex align-items-center mr-auto"
          aria-label="Navigate to home"
          component={RouterLink}
          to="/"
        >
          <img
            className={`${classes.icon} ${classes.brandIcon}`}
            alt="Buckeye Leaf Logo"
            src={darkMode ? buckeyeScarletWhite : buckeyeScarletWhite}
          />
          <Typography
            classes={{ root: classes.title }}
            className="pl-1 d-flex flex-column"
            variant="h1"
            component="span"
            noWrap
          >
            {PROJECT_NAME}
          </Typography>
        </ButtonBase>
        {user.status === "success" ? (
          <Fragment>
            <ButtonBase
              aria-label="Navigate to Contacts"
              component={RouterLink}
              to="/contacts"
            >
              <Typography
                classes={{ root: classes.tabs }}
                className="pr-2"
                variant="h2"
                component="span"
                noWrap
              >
                Contacts
              </Typography>
            </ButtonBase>
            <img
              className="buckeyeNut"
              alt="Buckeye Smooth Logo"
              src={
                darkMode ? buckeyeSmoothScarletWhite : buckeyeSmoothScarletWhite
              }
            />
            <ButtonBase
              aria-label="Navigate to Seasons and Events"
              component={RouterLink}
              to="/seasons"
            >
              <Typography
                classes={{ root: classes.tabs }}
                className="px-2 d-flex flex-column"
                variant="h2"
                component="span"
                noWrap
              >
                Seasons And Events
              </Typography>
            </ButtonBase>
            <img
              className="buckeyeNut"
              alt="Buckeye Smooth Logo"
              src={
                darkMode ? buckeyeSmoothScarletWhite : buckeyeSmoothScarletWhite
              }
            />
            <ButtonBase
              aria-label="Navigate to Reporting"
              component={RouterLink}
              to="/reporting"
            >
              <Typography
                color="secondary"
                classes={{ root: classes.title }}
                className="px-2 d-flex flex-column"
                variant="h6"
                component="span"
                noWrap
              >
                Reporting
              </Typography>
            </ButtonBase>
            <img
              className="buckeyeNut"
              alt="Buckeye Smooth Logo"
              src={
                darkMode ? buckeyeSmoothScarletWhite : buckeyeSmoothScarletWhite
              }
            />
            <ButtonBase
              aria-label="Navigate to Payments"
              component={RouterLink}
              to="/payments"
            >
              <Typography
                classes={{ root: classes.title }}
                className="pl-1 d-flex flex-column"
                variant="h6"
                component="span"
                noWrap
              >
                Payments
              </Typography>
            </ButtonBase>
            {props.children}
          </Fragment>
        ) : (
          ""
        )}
      </Toolbar>
    </MuiAppBar>
  );
}

export default AppBar;

AppBar.defaultProps = {
  className: "",
  elevation: 0,
  determineHeight: (height) => ({ height }),
};

AppBar.propTypes = {
  elevation: PropTypes.number,
  className: PropTypes.string,
  darkMode: PropTypes.bool,
  determineHeight: PropTypes.func,
};
