import React, { Fragment, useState, Suspense, lazy } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import SignInContainer from "../../Authentication/containers/SignIn";
import SignOut from "../../Authentication/components/SignOut";
import Home from "../../Components/Home/containers";
import { Helmet } from "react-helmet";
// import EditEventComponent from "../../Components/Events/EditEventComponent";
import ViewRequestByEvent from "../../Components/Requests/ViewRequestsByEvent";
import ViewRequestsByContact from "../../Components/Requests/ViewRequestsByContact";
import SeasonSelect from "../../Components/Seasons";
// import AddEventComponent from '../../Components/Events/AddEventComponent';
// import EditRequestComponent from '../../Components/Requests/components/EditRequest';
// import AddRequest from "../../Components/Requests/components/AddRequest";
import Contacts from "../../Components/Contacts";
import LoadFakeTickets from "../../Components/Tickets/LoadFakeTickets";
import Reporting from "../../Components/Reporting/";
// import AddTickets from "../../Components/Tickets/components/AddTicket";
// import AddContactComponent from "../../Components/Contacts/components/AddContactComponent";
import TicketSelect from "../../Components/Tickets/components/SeasonSelect";
// import ViewTickets from "../../Components/Tickets/components/ViewTickets";
// import SendEmail from "../../Components/Email/send-email";
import PaymentSelect from "../../Components/Payments/components";
// import AddPaymentComponent from "../../Components/Payments/components/AddPayment";
// import EditPaymentComponent from "../../Components/Payments/components/EditPayment";
import EmailBuilder from "../../Components/Email";
import InvoiceApp from "../../Components/Invoices";
import Thanks from "../../Components/Thanks";
// import NoComponentFound from './NoComponentFound';
function AppRoutes({ mobile = false, ...props }) {
  console.log(props);
  //lazy loading would help app load time, but running into issues with shared components so leaving
  //  to re-visit later
  const EditEventComponent = lazy(() =>
    import("../../Components/Events/EditEventComponent")
  );
  // const ViewRequestByEvent = lazy(() => import("../../Components/Requests/ViewRequestsByEvent"));
  // const SeasonSelect= lazy(() => import("../../Components/Seasons"));
  const AddEventComponent = lazy(() =>
    import("../../Components/Events/AddEventComponent")
  );
  const EditContactComponent = lazy(() =>
    import("../../Components/Contacts/components/EditContactComponent")
  );
  const EditRequestComponent = lazy(() =>
    import("../../Components/Requests/components/EditRequest")
  );
  const AddRequest = lazy(() =>
    import("../../Components/Requests/components/AddRequest")
  );
  // const Contacts= lazy(() => import("../../Components/Contacts/"));
  // const LoadFakeTickets= lazy(() => import("../../Components/Tickets/LoadFakeTickets"));
  const AddTickets = lazy(() =>
    import("../../Components/Tickets/components/AddTicket")
  );
  const ImportTickets = lazy(() =>
    import("../../Components/Tickets/components/ImportTickets")
  );
  const AddContactComponent = lazy(() =>
    import("../../Components/Contacts/components/AddContactComponent")
  );
  // const TicketSelect= lazy(() => import("../../Components/Tickets/components/SeasonSelect"));
  const ViewTickets = lazy(() =>
    import("../../Components/Tickets/components/ViewTickets")
  );
  const SendEmail = lazy(() => import("../../Components/Email/send-email"));
  // const PaymentSelect= lazy(() => import("../../Components/Payments/components"));
  // const AddPaymentComponent= lazy(() => import("../../Components/Payments/components/AddPayment"));
  // const EditPaymentComponent= lazy(() => import("../../Components/Payments/components/EditPayment"));
  // const NoComponentFound = lazy(() => import('./NoComponentFound'));
  const [title, setTitle] = useState("");
  const history = useHistory();
  const mobileOnlyRoutes = ["about-you"];
  const createTitle =
    (page = "") =>
    (description = "") => {
      setTitle(`${page}${description ? ` | ${description}` : ""}`);
    };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.length]);

  const routes = (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        {!mobile &&
          mobileOnlyRoutes.map((path, index) => (
            <Redirect key={index} from={`/${path}`} to="/" />
          ))}
        <Route
          exact
          path="/"
          render={() =>
            mobile ? (
              <Home generateTitle={createTitle("Ticket Tracking System")} />
            ) : (
              <Home generateTitle={createTitle("Ticket Tracking System")} />
            )
          }
        />
        <Route
          exact
          path="/login"
          render={() => (
            <SignInContainer generateTitle={createTitle("TTS Sign In")} />
          )}
        />
        <Route
          exact
          path="/logout"
          render={() => <SignOut generateTitle={createTitle("TTS Sign Out")} />}
        />
        <Route
          path="/seasons"
          render={(props) => (
            <SeasonSelect
              generateTitle={createTitle("TTS | Seasons")}
              {...props}
              seasonid={
                props.location.seasonid ? props.location.seasonid.seasonsid : ""
              }
            />
          )}
        />
        <Route
          path="/event/:eventid"
          render={(props) => (
            <EditEventComponent
              generateTitle={createTitle("TTS | Edit Event")}
              eventid={props.match.params.eventid}
            />
          )}
        />
        <Route path="/addevent/:seasonid" component={AddEventComponent} />
        <Route
          path="/tickets/:seasonid?/:eventid?"
          render={(props) => (
            <TicketSelect
              generateTitle={createTitle("TTS | Tickets")}
              {...props}
              seasonid={props.match.params.seasonid}
              eventid={props.match.params.eventid}
            />
          )}
        />
        <Route
          path="/seatingchart/:eventid"
          render={(props) => (
            <ViewTickets
              generateTitle={createTitle("TTS | Tickets")}
              eventid={props.match.params.eventid}
            />
          )}
        />
        <Route
          path="/ticketsload/:eventid"
          render={(props) => (
            <LoadFakeTickets
              generateTitle={createTitle("TTS | View Tickets")}
              eventid={props.match.params.eventid}
            />
          )}
        />
        <Route
          path="/addtickets/:seasonid/:eventid"
          render={(props) => (
            <AddTickets
              generateTitle={createTitle("TTS | Add Ticket")}
              eventid={props.match.params.eventid}
              seasonid={props.match.params.seasonid}
            />
          )}
        />
        <Route
          path="/importtickets/:eventid"
          render={(props) => (
            <ImportTickets
              generateTitle={createTitle("TTS | Import Tickets")}
              eventid={props.match.params.eventid}
            />
          )}
        />
        <Route
          path="/request/:requestid"
          render={(props) => (
            <EditRequestComponent
              generateTitle={createTitle("TTS | Edit Request")}
              requestid={props.match.params.requestid}
            />
          )}
        />
        <Route
          path="/addrequest/:eventid?"
          render={(props) => (
            <AddRequest
              generateTitle={createTitle("TTS | Add Request")}
              eventid={props.match.params.eventid}
            />
          )}
        />
        <Route
          path="/requests/:eventid/:status?"
          render={(props) => (
            <ViewRequestByEvent
              generateTitle={createTitle("TTS | Requests")}
              eventid={props.match.params.eventid}
            />
          )}
        />
        <Route
          path="/contactrequests/:contactid"
          render={(props) => (
            <ViewRequestsByContact
              generateTitle={createTitle("TTS | Requests")}
              contactid={props.match.params.contactid}
            />
          )}
        />
        <Route
          path="/payments/:contactid?/:requestid?"
          render={(props) => (
            <PaymentSelect
              generateTitle={createTitle("TTS | Payments")}
              contactid={props.match.params.contactid}
              requestid={props.match.params.requestid}
            />
          )}
        />
        {/* <Route path="/addpayment/:currentsearch?"  render={(props) => <AddPaymentComponent generateTitle={createTitle('TTS | Payments')} currentsearch={props.location.currentsearch?props.location.currentsearch.currentSearch:""} />} /> */}
        {/* <Route path="/payment/:paymentid" render={(props) => <EditPaymentComponent generateTitle={createTitle('TTS | Edit Payment')} paymentid={props.match.params.paymentid}  />} /> */}
        <Route path="/contact/:contactid" component={EditContactComponent} />
        <Route
          path="/addcontact"
          render={() => (
            <AddContactComponent
              generateTitle={createTitle("TTS | Add Contact")}
            />
          )}
        />
        <Route path="/contacts" component={Contacts} />
        <Route path="/reporting" component={Reporting} />
        <Route path="/sendemail" component={SendEmail} />
        <Route
          path="/emailbuilder"
          render={() => (
            <EmailBuilder generateTitle={createTitle("TTS | Email Builder")} />
          )}
        />
        <Route
          path="/invoice/:requestid?"
          render={(props) => (
            <InvoiceApp
              generateTitle={createTitle("TTS | Invoice")}
              requestid={props.match.params.requestid}
            />
          )}
        />
        <Route
          path="/thanks/:parameters?"
          render={() => (
            <Thanks generateTitle={createTitle("TTS | Thanks for payment")} />
          )}
        />
      </Switch>
    </Suspense>
  );

  return (
    <Fragment>
      <Helmet>{!!title && <title>{title}</title>}</Helmet>
      {routes}
    </Fragment>
  );
}

export default AppRoutes;
