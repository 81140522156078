import React, { Component } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import Amplify from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import IdleTimer from "react-idle-timer";
import aws_exports from "../../aws-exports";
import apiConfig from "../../api-config";
import CustomAuthStorage from "../../util/CustomAuthStorage";
import SignInContainer from "../../Authentication/containers/SignIn";
import theme from "../styles/theme";
import PropTypes from "prop-types";
import { AppContext } from "../context";
import { standardPalette, darkModePalette } from "../styles/colors";
import Footer from "../../Components/Footer";
import NavBar from "../../Components/Navbar/containers";
import AppRoutes from "./Routes";
import EmergencyAlert from "../../Components/EmergencyAlert/containers";
import "../../styles/index.scss";
import { redirectToLogIn } from "../../util/util";
import { PROJECT_NAME } from "../../util/constants";
import { GetContactData } from "../../actions-index";
import { GetSeasonList, GetEventList } from "../../util/dropdownconstants";
// import ReactJoyride from "react-joyride";
// import steps from "./joyridesteps";
Auth.configure({ storage: new CustomAuthStorage(apiConfig.customAuthStorage) });
Amplify.configure({ ...aws_exports, ...apiConfig });

const FIFTEEN_MINUTES = 60000 * 15;

class App extends Component {
  constructor(props) {
    super(props);
    const matchMedia = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;

    const validUser =
      props.user && props.user.status && props.user.status === "success";
    if (matchMedia) {
      this.updateColorVariables(matchMedia);
    }
    this.state = {
      darkMode: matchMedia,
      navIndex: 1,
      title: "",
    };
    if (!validUser && this.props.location.pathname !== "/logout") {
      this.props.setUser();
    }
    this.constructProviderValue = this.constructProviderValue.bind(this);
    this.setNavigationHeight = this.setNavigationHeight.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.createTitle = this.createTitle.bind(this);
  }
  state = {
    user: {},
    isLoading: true,
  };
  async componentDidMount() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await GetContactData();
      await GetSeasonList();
      await GetEventList();
      this.setState({ user, isLoading: false });
    } catch (err) {
      this.setState({ isLoading: false });
    }
  }
  // async componentWillReceiveProps(nextProps) {
  // 	try {
  // 		const user = await Auth.currentAuthenticatedUser();
  // 		this.setState({ user });
  // 	} catch (err) {
  // 		this.setState({ user: {} });
  // 	}
  // }
  updateColorVariables(darkMode) {
    let root = document.documentElement;
    const palette = darkMode ? darkModePalette : standardPalette;

    root.style.setProperty("--root-text-color", palette.textColor);
    root.style.setProperty(
      "--options-background-color",
      darkMode ? `var(--elevation8)` : palette.background.default
    );
    root.style.setProperty(
      "--root-background-color",
      palette.background.default
    );
  }
  createTitle =
    (page = "") =>
    (description = "") => {
      this.setState({
        title: `${page}${description ? ` | ${description}` : ""}`,
      });
    };

  updateColorMode(toggle = false) {
    this.setState(
      {
        darkMode: toggle,
      },
      () => this.updateColorVariables(this.state.darkMode)
    );
  }

  setNavigationHeight(height) {
    this.setState({ navHeight: height });
  }
  constructProviderValue() {
    const { navHeight } = this.state;
    return {
      mobileNavIndex: this.state.navIndex,
      user: this.props.user,
      signOut: this.handleLogoutClick,
      setUser: this.handleLoginClick,
      darkMode: this.state.darkMode,
      navHeight,
      palette: this.state.darkMode ? darkModePalette : standardPalette,
      setNavHeight: (height) =>
        !!height && height !== navHeight && this.setNavigationHeight(height),
      elevation: this.state.darkMode
        ? {
            standard: 2,
            high: 4,
            navigation: 6,
            tabs: 6,
            drawer: 12,
          }
        : {
            standard: 1,
            high: 2,
            tabs: 2,
            navigation: 4,
            drawer: 12,
          },
    };
  }
  _onIdle(e) {
    console.log("user is idle", e);
    console.log("last active", this.idleTimer.getLastActiveTime());
    Auth.signOut();
  }
  render() {
    const { user, location } = this.props;
    const providerValue = this.constructProviderValue();
    return (
      <React.Fragment>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          onIdle={this.onIdle}
          timeout={FIFTEEN_MINUTES}
        />
        <AppContext.Provider value={providerValue}>
          <ThemeProvider
            theme={responsiveFontSizes(
              theme(this.state.darkMode, providerValue.elevation)
            )}
          >
            <CssBaseline />
            <div className="main-wrapper">
              {process.env.REACT_APP_ENABLE_EMERGENCY_ALERT === "true" && (
                <EmergencyAlert className="py-1" />
              )}
              <NavBar
                hideSearch={this.props.location.pathname.includes("search")}
                toggleDarkMode={(toggle) => this.updateColorMode(toggle)}
                className="mb-2"
              />
              <Container component="main" className="container" id="main">
                {/* <ReactJoyride steps={steps} /> */}
                {location.pathname.startsWith("/thanks") ? (
                  <AppRoutes pathname={this.props.location.pathname} />
                ) : user.status === "loading" ? (
                  <Typography
                    className="d-flex flex-column align-items-center"
                    variant="h1"
                  >
                    Loading...
                    <CircularProgress
                      className="mt-2"
                      width={60}
                      height={60}
                      color="primary"
                    />
                  </Typography>
                ) : user.status === "error" ||
                  location.pathname === "/logout" ? (
                  <Typography
                    variant="h1"
                    className="d-flex flex-column align-items-center"
                  >
                    Log in to {PROJECT_NAME}
                    <Button
                      className="mt-2"
                      variant="contained"
                      color="primary"
                      aria-label={`Log in to ${PROJECT_NAME}`}
                      onClick={() => redirectToLogIn()}
                    >
                      Log In
                    </Button>
                  </Typography>
                ) : user.status === "success" ? (
                  <div>
                    {/* <SimpleBreadcrumbs /> */}
                    <AppRoutes
                      pathname={this.props.location.pathname}
                      user={this.props.user}
                    />
                  </div>
                ) : (
                  <SignInContainer
                    generateTitle={this.createTitle("TTS Sign In")}
                  />
                )}
              </Container>
            </div>
            <Footer className="mt-3" />
          </ThemeProvider>
        </AppContext.Provider>
      </React.Fragment>
    );
  }
  handleLogoutClick = () => {
    Auth.signOut();
  };
}
App.propTypes = {
  user: PropTypes.shape({
    applicationRoles: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    osuid: PropTypes.string,
    username: PropTypes.string,
  }),
  setUser: PropTypes.func,
};
export default React.memo(
  App,
  (prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);
