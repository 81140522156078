import API from "@aws-amplify/api";
import _ from "lodash";
import { getAllData, GetEventList } from "../../util/dropdownconstants";
import { StringToBoolean } from "../../util/util";

export async function GetEventByEventID(eventId) {
  var eventData = {};
  let eventList = await GetEventList();
  eventData = eventList.filter((event) => {
    return event.pk === eventId;
  })[0];
  eventData.PostSeasonEvent = StringToBoolean(eventData.PostSeasonEvent);
  return eventData;
}
export async function GetTicketsByRequests(eventId, reqArray) {
  var titleObject = [],
    basictitleObject = {};
  var index = 0,
    currentIndex = 0,
    counter = 0,
    max = 99,
    titleKey = "",
    filterExp = "";
  titleObject[currentIndex] = {};
  reqArray.forEach(function (request) {
    index++;
    titleKey = ":titlevalue" + index;
    basictitleObject[titleKey.toString()] = eventId + "#" + request.pk;
    if (counter < max) {
      titleObject[currentIndex][titleKey] = request.pk;
      counter++;
    } else {
      currentIndex++;
      titleObject[currentIndex] = {};
      titleObject[currentIndex][titleKey] = request.pk;
      counter = 0;
    }
  });
  for (let i = 0; i < titleObject.length; i++) {
    if (i === 0) {
      filterExp += "#data IN (" + Object.keys(titleObject[i]).toString() + ")";
    } else {
      filterExp +=
        " OR #data IN (" + Object.keys(titleObject[i]).toString() + ") ";
    }
  }
  var paramsTickets = {
    operation: "read",
    payload: {
      IndexName: "sk-index",
      KeyConditions: {
        sk: {
          ComparisonOperator: "EQ",
          AttributeValueList: { S: "Ticket" },
        },
      },
      FilterExpression: filterExp,
      ExpressionAttributeNames: { "#data": "data" },
      // ProjectionExpression: "pk, RequestID, Price, AmountPaid",
      ExpressionAttributeValues: basictitleObject,
    },
  };
  const pe1 = await getAllData(paramsTickets);
  return pe1;
}
export async function GetRequestsByEvent(reqarray) {
  var paramsRequests = {
    operation: "read",
    payload: {
      IndexName: "gsi_1",
      KeyConditionExpression:
        "sk = :objecttype and begins_with(#data,:eventid) ",
      ExpressionAttributeNames: {
        "#data": "data",
      },
      FilterExpression: "EventID = :eventid",
      ExpressionAttributeValues: {
        ":objecttype": "Request",
        ":eventid": reqarray.eventId,
      },
    },
  };
  if (reqarray.status !== undefined) {
    paramsRequests.payload.FilterExpression =
      "RequestStatus = :requeststatus and EventID = :eventid";
    paramsRequests.payload.ExpressionAttributeValues = {
      ":requeststatus": reqarray.status,
      ":objecttype": "Request",
      ":eventid": reqarray.eventId,
    };
  }
  const requestarray = await getAllData(paramsRequests);
  const responseData = requestarray;
  responseData.map(function (tcid) {
    Object.keys(tcid).forEach((key) => {
      if (tcid[key] === "NULL") {
        tcid[key] = "";
      }
    });
    return tcid;
  });

  return responseData;
}
export async function GetRequestsByContact(reqarray) {
  var paramsRequests = {
    operation: "read",
    payload: {
      IndexName: "gsi_1",
      KeyConditionExpression: "sk = :objecttype",
      FilterExpression: "ContactID = :contactid",
      ExpressionAttributeValues: {
        ":objecttype": "Request",
        ":contactid": reqarray.contactId,
      },
    },
  };
  var responseData = await getAllData(paramsRequests);
  responseData = responseData.map(function (tcid) {
    Object.keys(tcid).forEach((key) => {
      if (tcid[key] === "NULL") {
        tcid[key] = "";
      }
    });
    return tcid;
  });
  var paramsRequestedFor = {
    operation: "read",
    payload: {
      IndexName: "gsi_1",
      KeyConditionExpression: "sk=:objecttype",
      FilterExpression: "RequestedFor = :id",
      ExpressionAttributeValues: {
        ":objecttype": "Request",
        ":id": reqarray.contactId,
      },
    },
  };
  var requestedforresults = await getAllData(paramsRequestedFor);
  let result = _.unionBy(responseData, requestedforresults, "pk");
  return result;
}
