import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
// import Link from "@material-ui/core/Link";
import { Link } from "react-router-dom";
// import { Button } from "@material-ui/core";
import { Form, Modal } from "semantic-ui-react";
import PaymentEmail from "./PaymentEmail";
import {
  Table,
  TableBody,
  Button,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { EventNote, Receipt } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
  },
}));

export default function CenteredGrid(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(2);
  const [page, setPage] = React.useState(0);
  // const [expanded, setExpanded] = React.useState("");
  var {
    eventSeason,
    currentEvent,
    contactname,
    requestedfor,
    row,
    contactnotes,
  } = props;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  var emaillist = [];
  if (
    row.ContactInfo.EmailAddress &&
    row.ContactInfo.EmailAddress !== "" &&
    row.ContactInfo.EmailAddress !== "NULL"
  ) {
    emaillist.push(row.ContactInfo.EmailAddress.toLowerCase());
  }
  if (
    row.ContactInfo.EmailAddress2 &&
    row.ContactInfo.EmailAddress2 !== "" &&
    row.ContactInfo.EmailAddress2 !== "NULL"
  ) {
    emaillist.push(row.ContactInfo.EmailAddress2.toLowerCase());
  }
  if (
    row.RequestedForInfo.EmailAddress &&
    row.RequestedForInfo.EmailAddress !== "" &&
    row.RequestedForInfo.EmailAddress !== "NULL"
  ) {
    emaillist.push(row.RequestedForInfo.EmailAddress.toLowerCase());
  }
  if (
    row.RequestedForInfo.EmailAddress2 &&
    row.RequestedForInfo.EmailAddress2 !== "" &&
    row.RequestedForInfo.EmailAddress2 !== "NULL"
  ) {
    emaillist.push(row.RequestedForInfo.EmailAddress2.toLowerCase());
  }
  emaillist = Array.from(new Set(emaillist.map((row) => row)));
  console.log(contactname);
  var fullName = contactname
    ? contactname.substring(0, contactname.indexOf("("))
    : "";
  // add space between names
  var description, season;
  fullName = fullName.replace(/\s+/g, "%20");
  if (description) {
    description = currentEvent.replace(/\s+/g, "%20");
  } else {
    description = row.eventInfo.EventName.replace(/\s+/g, "%20");
  }
  if (eventSeason) {
    season = eventSeason.replace(/\s+/g, "%20");
  } else {
    season = row.eventInfo.data.replace(/\s+/g, "%20");
  }

  const handleCancel = () => setExpanded("");
  // }
  var ticketarray = [];
  var ticketsassigned = [];
  ticketsassigned = row.tickets ? row.tickets : [];
  ticketarray =
    row.NumberTicketsRequested || row.NumberTicketsPromised
      ? [
          {
            TicketCategoryID: row.TicketCategoryID,
            NumberTicketsRequested: row.NumberTicketsRequested,
            NumberTicketsPromised: row.NumberTicketsPromised,
          },
        ]
      : row.TicketsRequested
      ? row.TicketsRequested[0]
        ? row.TicketsRequested
        : ""
      : "";
  var newPaymentRequests, PaymentRequestColumns;
  if (row.PaymentRequests && row.PaymentRequests.length > 0) {
    newPaymentRequests = row.PaymentRequests;
    for (const [i, req] of newPaymentRequests.entries()) {
      req.id = i;
      req.PaymentRequestSentTo = req.PaymentRequestSentTo.replace(/,/g, ", ");
    }
    PaymentRequestColumns = [
      {
        field: "DatePaymentRequestSent",
        headerName: "Sent On",
      },
      { field: "PaymentRequestSentBy", headerName: "Sent By" },
      {
        field: "PaymentRequestSentTo",
        headerName: "Sent To",
      },
    ];
  }
  return (
    <div className={classes.root} key={`centdiv-${row.pk}`}>
      <Grid container spacing={3} key={`grid-${row.pk}`}>
        <Grid item xs={6}>
          <Paper className={classes.paper} square>
            Contact Name:{" "}
            <Link to={`/contact/${row.ContactID}`}>
              {row.isOrganization ? row.Organization : contactname}
            </Link>
          </Paper>
          {requestedfor ? (
            <Paper className={classes.paper} square>
              Requested for:{" "}
              <Link to={`/contact/${row.RequestedForInfo.ContactID}`}>
                {requestedfor}
              </Link>
            </Paper>
          ) : (
            <div></div>
          )}
        </Grid>

        <Grid item xs={6}>
          <Paper className={classes.paper}>Request Notes: {row.Notes}</Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>Contact Notes: {contactnotes}</Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <b>#Tickets Requested/Promised by Ticket Type</b>
            <br />
            {ticketarray.map((ticket) => (
              <div key={`${ticket.TicketCategoryID}-${row.pk}`}>
                <p>
                  <b>{ticket.TicketCategoryID}: </b>
                  {ticket.NumberTicketsRequested || 0} Tickets Requested,{" "}
                  {ticket.NumberTicketsPromised || 0} Tickets Promised
                  {/* {ticket.NumberTicketsPromised || 0} Tickets Promised,{" "}
                  {
                    ticketsassigned.filter((item) => {
                      return item.TicketType === ticket.TicketCategoryID;
                    }).length
                  }{" "}
                  Tickets Assigned */}
                </p>
              </div>
            ))}
            {/* {ticketarray.map((ticket) => (
              <p>
                {ticket.NumberTicketsPromised}, {ticket.NumberTicketsRequested}
              </p>
            ))} */}
            {/* <b># Tickets: </b> <br /> {ticketString} */}
          </Paper>
        </Grid>
        {/* <Grid item xs={3}>
          <Paper className={classes.paper}>
            <b># Tickets Promised: </b> {mathprom}
          </Paper>
        </Grid> */}
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            {/* <Link to={`/request/${row.pk}`}>
              <p>Edit Request Details </p>
            </Link> */}
            <Tooltip
              key={`requestbutton${row.pk}`}
              aria-label="requestbutton"
              title="View/Edit Request"
            >
              <Button
                startIcon={<EventNote />}
                component={Link}
                to={`/request/${row.pk}`}
              >
                View/Edit Request Details
              </Button>
            </Tooltip>
            <Tooltip
              key={`invoicebutton${row.pk}`}
              aria-label="invoicebutton"
              title="Generate Invoice"
            >
              <Button
                startIcon={<Receipt />}
                component={Link}
                to={`/invoice/${row.pk}`}
              >
                Generate Invoice
              </Button>
            </Tooltip>
            {/* <Link to={`/invoice/${row.pk}`}>
              <p>Generate Invoice </p>
            </Link> */}
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <Modal
              // style={{ width: "40vw", height: "60vh" }}
              onClose={() => handleCancel()}
              onOpen={() => setExpanded(row.ContactID)}
              open={expanded === row.ContactID}
              trigger={
                <Form.Button className="badge badge-danger">
                  Send Payment Link to Customer
                </Form.Button>
              }
            >
              <Modal.Content scrolling>
                <PaymentEmail
                  emaillist={emaillist}
                  row={row}
                  fullName={fullName}
                  description={description}
                  season={season}
                />
              </Modal.Content>
            </Modal>
          </Paper>
        </Grid>

        {newPaymentRequests && PaymentRequestColumns ? (
          <Grid item xs={5}>
            <TableContainer>
              <Table aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>SentBy</TableCell>
                    <TableCell>SentOn</TableCell>
                    <TableCell>SentTo</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {newPaymentRequests
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((req, index) => (
                      <TableRow key={`${index}-${row.pk}`}>
                        <TableCell component="th" scope="row">
                          {req.PaymentRequestSentBy.split("@osu.edu")[0]}
                        </TableCell>
                        <TableCell>{req.DatePaymentRequestSent}</TableCell>
                        <TableCell
                          style={{
                            wordWrap: "break-word",
                            whiteSpace: "normal",
                          }}
                        >
                          {req.PaymentRequestSentTo}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[2, 4, 6]}
              component="div"
              count={newPaymentRequests.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}
