import API from "@aws-amplify/api";
import React, { Component } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "react-router-dom";
import { Message } from "semantic-ui-react";
import map from "lodash/map";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import { CSVLink } from "react-csv";
import { getAllData } from "../../../actions-index";
import { DataGrid } from "@mui/x-data-grid";
import { GetCurrentDateTime } from "../../../util/util";

class App extends Component {
  constructor(props) {
    super(props);
    // const matchMedia = window.matchMedia("(prefers-color-scheme: dark)")
    // 	.matches;
    this.handleClick = this.handleClick.bind(this);
    this.handleEditRowsModelChange = this.handleEditRowsModelChange.bind(this);
    this.deleteTickets = this.deleteTickets.bind(this);
    this.updateTicket = this.updateTicket.bind(this);
    this.download = this.download.bind(this);
    this.GetDownloadData = this.GetDownloadData.bind(this);
    this.state = {
      editRowsModel: [],
      EventInfo: {},
      deleted: false,
      deletedtext: 0,
      selectedEvent: "",
      dataToDownload: [],
      // darkMode: matchMedia,
      isLoading: true,
      is2Loading: true,
      page: "",
      dataSource: {},
      ticketsBySeason: [],
      selectedSeason: "",
      allSeasons: [],
      column: null,
      direction: null,
      checked: [],
    };
    this.user = sessionStorage.getItem("UserName");
  }
  handleClick = (value) => {
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = [...this.state.checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({ checked: newChecked });
  };
  handleEditRowsModelChange = (model) => {
    this.setState({ editRowsModel: model });
  };
  async deleteTickets() {
    const checkedlist = this.state.checked;
    for (let ticket of checkedlist) {
      var paramsDelete = {
        operation: "delete",
        payload: {
          Key: {
            pk: ticket,
            sk: "Ticket",
          },
        },
      };

      API.post(process.env.REACT_APP_TICKET_TRACKING_API_NAME, "/items", {
        body: paramsDelete,
      });
    }
    await this.GetData(this.props.data, this.props.EventInfo);
    await this.GetDownloadData();
    this.setState({
      deleted: true,
      deletedtext: checkedlist.length,
      checked: [],
    });
  }
  async updateTicket(ticket) {
    const datetime = await GetCurrentDateTime();
    var updateExp = `set ${ticket.field}=:val,LastUpdatedBy=:user, LastUpdated=:date`;
    var paramsUpdate = {
      operation: "update",
      payload: {
        Key: {
          pk: ticket.id,
          sk: "Ticket",
        },
        UpdateExpression: updateExp,
        ExpressionAttributeValues: {
          ":val": ticket.value,
          ":user": this.user,
          ":date": datetime,
        },
      },
    };
    API.post(process.env.REACT_APP_TICKET_TRACKING_API_NAME, "/items", {
      body: paramsUpdate,
    }).then((response) => console.log(response));
    await this.GetData(this.props.data, this.props.EventInfo);
    await this.GetDownloadData();
    // this.setState({
    //   deleted: true,
    //   deletedtext: checkedlist.length,
    //   checked: [],
    // });
  }

  handleSort = (clickedColumn) => () => {
    const { column, eventsBySeason, direction } = this.state;

    if (column !== clickedColumn) {
      if (clickedColumn === "EventDate") {
        this.setState({
          column: clickedColumn,
          eventsBySeason: sortBy(eventsBySeason, function (dateObj) {
            return new Date(dateObj.EventDate);
          }),
          direction: "ascending",
        });
      } else {
        this.setState({
          column: clickedColumn,
          eventsBySeason: sortBy(eventsBySeason, [
            (eventsBySeason) =>
              get(eventsBySeason, clickedColumn).toLowerCase(),
          ]),
          direction: "ascending",
        });
      }
      return;
    }

    this.setState({
      eventsBySeason: eventsBySeason.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };
  async componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({ isLoading: true });
      await this.GetData(this.props.data, this.props.EventInfo);
      await this.GetDownloadData();
    }
  }
  async componentDidMount() {
    await this.GetData(this.props.data, this.props.EventInfo);
    await this.GetDownloadData();

    this.setState({ isLoading2: false });
  }

  async GetDownloadData() {
    const columns = [
      {
        Header: "TicketType",
        accessor: "TicketType", // String-based value accessors!
      },
      {
        Header: "Section",
        accessor: "Section", // String-based value accessors!
      },
      {
        Header: "Row",
        accessor: "Row",
      },
      {
        Header: "Seat",
        accessor: "Seat", // String-based value accessors!
      },
      {
        Header: "Price",
        accessor: "Price", // String-based value accessors!
      },
      {
        Header: "TicketStatus",
        accessor: "TicketStatus", // String-based value accessors!
      },
      {
        Header: "ContactInfo",
        accessor: "ContactInfo", // String-based value accessors!
      },
      {
        Header: "RequestedFor",
        accessor: "RequestedFor", // String-based value accessors!
      },
      {
        Header: "Venue",
        accessor: "Venue", // String-based value accessors!
      },
    ];
    const currentRecords = this.state.ticketList.map(
      ({
        TicketType,
        Section,
        Row,
        Seat,
        Price,
        TicketStatus,
        ContactInfo,
        RequestedFor,
        Venue,
      }) => ({
        TicketType,
        Section,
        Row,
        Seat,
        Price,
        ContactInfo,
        TicketStatus,
        RequestedFor,
        Venue,
      })
    );
    var data_to_download = [];
    for (var index = 0; index < currentRecords.length; index++) {
      let record_to_download = {};
      for (var colIndex = 0; colIndex < columns.length; colIndex++) {
        record_to_download[columns[colIndex].Header] =
          currentRecords[index][columns[colIndex].accessor];
      }
      data_to_download.push(record_to_download);
    }
    this.setState({ dataToDownload: data_to_download });
  }
  async download() {
    if (!this.state.isLoading2) {
      await this.GetDownloadData();
      // click the CSVLink component to trigger the CSV download
      this.csvLink.link.click();
    }
  }
  async GetData(data, eventinfo) {
    try {
      this.state.selectedEvent = data;
      var paramsEvent = {
        operation: "read",
        payload: {
          IndexName: "gsi_1",
          KeyConditionExpression: "sk = :objecttype",
          FilterExpression: "EventID = :eventid  ",
          ExpressionAttributeValues: {
            ":objecttype": "Ticket",
            ":eventid": this.state.selectedEvent,
          },
        },
      };
      const ticketData = await getAllData(paramsEvent);
      const sortedticketdata = sortBy(ticketData, [
        "TicketType",
        "Section",
        "Row",
        "Seat",
      ]);
      for (var ticket of sortedticketdata) {
        ticket.id = ticket.pk;
        ticket.seatinfo =
          "Section: " +
          ticket.Section +
          "; Row: " +
          ticket.Row +
          "; Seat: " +
          ticket.Seat;
        if (
          ticket.RequestInfo &&
          ticket.RequestInfo.RequestedByName &&
          ticket.RequestInfo.RequestedByName !== "NONE" &&
          ticket.RequestID !== "NONE"
        ) {
          var contactInfo =
            ticket.RequestInfo.RequestedByName.split("firstname=");
          contactInfo = contactInfo[1]
            ? contactInfo[1].split("#lastname=")
            : "";
          const FirstName = contactInfo[0] || "";
          contactInfo = contactInfo[1]
            ? contactInfo[1].split("#email=")
            : contactInfo;
          const LastName = contactInfo[1]
            ? contactInfo[0]
            : contactInfo[0].split("#org=")[0];
          contactInfo = contactInfo[1]
            ? contactInfo[1].split("#org=")
            : contactInfo[0].split("#org=");
          //  const Email=contactInfo[0];
          let Organization = contactInfo[1] || "";
          Organization =
            Organization !== "NULL" && Organization !== "" ? Organization : "";
          ticket.ContactInfo =
            FirstName !== "NULL" && FirstName !== ""
              ? FirstName + " " + LastName
              : Organization;
        } else {
          ticket.ContactInfo = "";
        }
        if (ticket.RequestID !== "NONE") {
          if (
            ticket.RequestInfo &&
            ticket.RequestInfo.RequestedForID !== ticket.ContactID
          ) {
            var requestInfo =
              ticket.RequestInfo.RequestedForName.split("firstname=");
            requestInfo = requestInfo[1]
              ? requestInfo[1].split("#lastname=")
              : "";
            const FirstName = requestInfo[0] || "";
            requestInfo = requestInfo[1]
              ? requestInfo[1].split("#email=")
              : requestInfo;
            const LastName = requestInfo[1]
              ? requestInfo[0]
              : requestInfo[0].split("#org=")[0];
            requestInfo = requestInfo[1]
              ? requestInfo[1].split("#org=")
              : requestInfo[0].split("#org=");
            //  const Email=contactInfo[0];
            let Organization = requestInfo[1] || "";
            Organization =
              Organization !== "NULL" && Organization !== ""
                ? " (" + Organization + ")"
                : "";
            ticket.RequestedFor =
              FirstName !== "NULL" && FirstName !== ""
                ? FirstName + " " + LastName
                : Organization;
            // ticket.RequestedFor = requestinfo.Item.data;
          } else {
            ticket.RequestedFor = "";
          }
        }
      }
      this.setState({
        ticketList: sortedticketdata,
        EventInfo: eventinfo,
        isLoading: false,
      });
    } catch (err) {}
  }

  render() {
    const { isLoading, isLoading2, deleted, deletedtext } = this.state;
    if (isLoading || isLoading2) {
      return <Typography variant="h2">Loading Tickets...</Typography>;
    } else {
      const { checked, ticketList, editRowsModel } = this.state;
      const columns = [
        {
          field: "pk",
          headerName: " ",
          renderCell: (params) => (
            <ListItem
              key={params.id}
              role={undefined}
              dense
              button
              onClick={() => {
                this.handleClick(params.id);
              }}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked.indexOf(params.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  // inputProps={{
                  //   "aria-labelledby":
                  //     Section + Row + Seat + "TicketStatus: " + TicketStatus,
                  // }}
                />
              </ListItemIcon>
            </ListItem>
            // <strong>
            //   <Typography variant="body2">
            //     {params.getValue(params.id,"")}/{params.row.TotalCount}
            //   </Typography>
            // </strong>
          ),
        },
        {
          field: "TicketType",
          headerName: "TicketType",
          width: 180,
          editable: false,
        },
        {
          field: "TicketStatus",
          headerName: "TicketStatus",
          width: 180,
          editable: true,
        },
        {
          field: "seatinfo",
          headerName: "Seat Info",
          width: 280,
          editable: false,
        },
        {
          field: "ContactInfo",
          headerName: "ContactInfo",
          width: 180,
          editable: false,
        },
        {
          field: "RequestedFor",
          headerName: "RequestedFor",
          width: 180,
          editable: false,
        },
      ];

      return (
        <div>
          <br />
          <Typography variant="h4" style={style}>
            Event Tickets {ticketList.length}
          </Typography>
          <Link
            to={`/addtickets/${this.state.EventInfo.data}/${this.state.selectedEvent}`}
          >
            <Button style={scarletWhite}>Add Tickets</Button>
          </Link>
          <Button
            onClick={() => this.deleteTickets()}
            style={scarletWhite}
            disabled={checked.length === 0}
          >
            Delete {checked.length} Selected Tickets
          </Button>
          <Message success hidden={!deleted}>
            <p>{deletedtext} ticket(s) deleted</p>
          </Message>
          <br />
          <br />
          <Button onClick={this.download} style={scarletWhite}>
            Download Ticket Spreadsheet
          </Button>
          <CSVLink
            data={this.state.dataToDownload}
            filename={`${this.state.EventInfo.data}_${this.state.EventInfo.EventName}.csv`}
            className="hidden"
            ref={(r) => (this.csvLink = r)}
            target="_blank"
          />
          <div className="scarlettable">
            <DataGrid
              overflowX="scroll"
              id="datagrid"
              rows={ticketList}
              columns={columns}
              pageSize={100}
              rowsPerPageOptions={[100, 50, 25, 10]}
              onCellEditCommit={this.updateTicket}
              editRowsModel={editRowsModel}
              editMode="cell"
              onEditRowsModelChange={this.handleEditRowsModelChange}
              autoHeight={true}
            />
          </div>
          {/* <List>
            {map(
              ticketList,
              ({ pk, Section, Row, Seat, TicketStatus, TicketType, Price }) => (
                <ListItem
                  key={pk}
                  role={undefined}
                  dense
                  button
                  onClick={() => {
                    this.handleClick(pk);
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(pk) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        "aria-labelledby":
                          Section +
                          Row +
                          Seat +
                          "TicketStatus: " +
                          TicketStatus,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={pk}
                    primary={
                      "Ticket Type: " +
                      TicketType +
                      "; Section: " +
                      Section +
                      "; Row: " +
                      Row +
                      "; Seat: " +
                      Seat +
                      "; Ticket Status: " +
                      TicketStatus +
                      "; Ticket Price: $" +
                      Price
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="comments"></IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            )}
          </List> */}
        </div>
      );
    }
  }
}
const style = {
  display: "flex",
  justifyContent: "center",
};
const scarletWhite = {
  backgroundColor: "#BB0000",
  color: "#fff",
};

export default App;
