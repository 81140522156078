import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers-index";
import { composeWithDevTools } from "redux-devtools-extension";

export default function configureStore(initialState) {
  let middleware = [thunk];

  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  );
}
