import React from "react";
import SeasonSelect from "./SeasonSelect";
import { GetSeasonList } from "../../actions-index";

class Seasons extends React.Component {
  constructor(props) {
    super(props);
    this.seasonid = this.props.seasonid;
    props.generateTitle();
    this.state = {
      isLoading: true,
      page: "",
      dataSource: {},
      seasonData: [],
      seasonYears: [],
      seasonCategories: [],
      seasonstring: "",
    };
  }
  async componentDidMount() {
    const seasondataload = await GetSeasonList();
    this.setState({
      seasonCategories: seasondataload.uniqueCategories,
      seasonData: seasondataload.uniqueYears,
      seasonYears: seasondataload.uniqueYears,
      isLoading: false,
    });
  }
  render() {
    if (this.state.isLoading || this.state.seasonCategories.length < 1) {
      return <div>Loading...</div>;
    } else {
      return (
        <div>
          <SeasonSelect sid={this.seasonid} />
        </div>
      );
    }
  }
}
export default Seasons;
