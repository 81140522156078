import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import InvoiceTableHeader from "./InvoiceTableHeader";
import InvoiceTableRow from "./InvoiceTableRow";
import InvoiceTableBlankSpace from "./InvoiceTableBlankSpace";
import InvoiceTableFooter from "./InvoiceTableFooter";

const tableRowsCount = 11;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
});
const InvoiceItemsTable = ({ invoice }) => {
  var tickettypes = [],
    eventTicketsByPrice = [],
    x = {};
  const countPrice = Array.from(new Set(invoice.Items.map((row) => row.Price)));
  countPrice.forEach((price) => {
    tickettypes = Array.from(
      new Set(
        invoice.Items.filter((el) => el.Price === price).map(
          (row) => row.TicketType
        )
      )
    ).join();
    var EventName = Array.from(
      new Set(
        invoice.Items.filter((el) => el.Price === price).map(
          (row) => row.EventName + " (" + row.EventSeason + ")"
        )
      )
    ).join();
    x = {
      EventName: EventName,
      // EventSeason: invoice.RequestInfo.EventSeason,
      Price: price,
      TicketType: tickettypes,
      Quantity: invoice.Items.filter((el) => el.Price === price).length,
    };
    eventTicketsByPrice.push(x);
  });
  // const EventArray = Array.from(
  //   new Set(invoice.Items.map((row) => row.EventSeason)),
  //   new Set(invoice.Items.map((row) => row.EventName)),
  //   new Set(invoice.Items.map((row) => row.Price))
  // );
  return (
    <View style={styles.tableContainer}>
      <InvoiceTableHeader />
      <InvoiceTableRow items={eventTicketsByPrice} />
      <InvoiceTableBlankSpace
        rowsCount={tableRowsCount - invoice.Items.length}
      />
      <InvoiceTableFooter items={eventTicketsByPrice} />
    </View>
  );
};

export default InvoiceItemsTable;
